import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogGrid from '../components/blog/BlogProjects'

const Project = () => {
    const { t } = useTranslation('project')

    return (
        <Layout>
            <div className="projectPreview">
            <SEO title="project:title" />
            <h1 className="pageTitle">{t('title')}</h1>
            <BlogGrid preview/>
            </div>        
        </Layout>
    )
}
export default Project
